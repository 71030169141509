.cta-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.cta-container1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: radial-gradient(circle at center, rgba(189, 195, 199, 0) 0.00%,rgb(13, 13, 13) 98.00%),url('https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d533_background.svg');
  background-position: top left,center;
}
.cta-container2 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.cta-container3 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cta-link {
  display: contents;
}
.cta-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: #333333;
  border-width: 2px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  backdrop-filter: blur(10px);
  justify-content: center;
  text-decoration: none;
  background-color: rgba(13, 13, 13, 0.4);
}
.cta-container4:hover {
  border-color: var(--dl-color-theme-primary2);
  background-color: rgba(13, 13, 13, 0.4);
}
.cta-text1 {
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.cta-icon {
  fill: var(--dl-color-theme-neutral-dark);
  width: 24px;
  height: 24px;
}
.cta-text2 {
  display: inline-block;
}
.cta-text3 {
  display: inline-block;
  font-size: 24px;
  margin-left: var(--dl-space-space-twounits);
}
.cta-root-class-name {
  width: 100%;
  max-width: 1600px;
  align-self: center;
}
@media(max-width: 991px) {
  .cta-container1 {
    flex-direction: column;
  }
}
