.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.footer-text {
  font-style: normal;
  font-weight: 100;
}
.footer-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: 0.5rem;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #333333;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
}
.footer-container2:hover {
  background-color: #333333;
}
.footer-image {
  width: 25px;
  filter: invert();
  object-fit: cover;
}
.footer-text1 {
  display: inline-block;
}

