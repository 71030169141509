.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-nav-container {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 100;
  position: fixed;
  max-width: 1600px;
  align-items: center;
  flex-direction: column;
}
.home-text {
  display: inline-block;
}

.home-text01 {
  display: inline-block;
}
.home-text02 {
  display: inline-block;
}
.home-text03 {
  display: inline-block;
  text-align: center;
}
.home-text04 {
  color: var(--dl-color-theme-primary2);
}
.home-text06 {
  display: inline-block;
  text-decoration: none;
}
.home-text07 {
  display: inline-block;
  text-decoration: none;
}
.home-text08 {
  display: inline-block;
  text-decoration: none;
}
.home-text09 {
  display: inline-block;
  text-align: center;
}
.home-text10 {
  color: var(--dl-color-theme-primary2);
}
.home-text12 {
  display: inline-block;
}

.home-text13 {
  display: inline-block;
}
.home-text14 {
  display: inline-block;
}
.home-text15 {
  display: inline-block;
  text-align: center;
}
.home-text16 {
  color: var(--dl-color-theme-primary2);
}
.home-hero {
  flex: 0 0 auto;
  width: var(--dl-size-size-maxwidth);
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: radial-gradient(circle at center, rgba(189, 195, 199, 0) 17.00%,rgb(13, 13, 13) 57.00%),url('https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d533_background.svg');
}
.home-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container02 {
  flex: 0 0 auto;
  width: 600px;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-fiveunits);
  margin-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text18 {
  font-size: 36px;
  align-self: flex-start;
  text-align: left;
  font-family: "Geologica";
  font-weight: 300;
}
.home-text20 {
  color: #64c7ff;
  font-style: normal;
  font-weight: 900;
}
.home-text22 {
  color: rgb(144, 144, 144);
  text-align: left;
  padding-top: var(--dl-space-space-unit);
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-link {
  display: contents;
}
.home-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-theme-primary2);
  border-width: 2px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-container05:hover {
  box-shadow: 0px 0px 40px 0px #ec5fff;
  background-color: #ec5fff;
}
.home-text23 {
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.home-icon {
  fill: var(--dl-color-theme-neutral-dark);
  width: 24px;
  height: 24px;
}
.home-link1 {
  display: contents;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  margin-left: var(--dl-space-space-oneandhalfunits);
  padding-top: var(--dl-space-space-unit);
  border-color: #333333;
  border-width: 2px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-container06:hover {
  background-color: #333333;
}
.home-text24 {
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.home-icon2 {
  fill: var(--dl-color-theme-neutral-dark);
  width: 24px;
  height: 24px;
}
.home-container07 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: column;
}
.home-image {
  width: 459px;
  height: 600px;
  object-fit: cover;
}
.home-work {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #696969;
  border-style: dashed;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
}
.home-container08 {
  flex: 0 0 auto;
  width: auto;
  height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text25 {
  font-size: 3rem;
  font-style: normal;
  text-align: center;
  font-family: "Geologica";
  font-weight: 700;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.home-text26 {
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Geologica";
  font-weight: 200;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.home-capabilities {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: center;
}
.home-container09 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: radial-gradient(circle at center, rgba(189, 195, 199, 0) 0.00%,rgb(13, 13, 13) 98.00%),url('https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d533_background.svg');
}
.home-text27 {
  font-size: 3rem;
}
.home-container10 {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.home-container11 {
  flex: 0 0 auto;
  width: 400px;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image1 {
  width: 60px;
  object-fit: cover;
}
.home-text28 {
  font-size: 26px;
  font-style: normal;
  text-align: center;
  font-family: "Geologica";
  font-weight: 500;
  padding-top: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-text29 {
  color: #adadad;
  font-size: 14px;
  text-align: center;
  font-family: "Geologica";
  font-weight: 200;
  line-height: 1;
  padding-top: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.home-container12 {
  flex: 0 0 auto;
  width: 400px;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image2 {
  width: 70px;
  object-fit: cover;
}
.home-text30 {
  font-size: 26px;
  font-style: normal;
  text-align: center;
  font-family: "Geologica";
  font-weight: 500;
  padding-top: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-text31 {
  color: rgb(173, 173, 173);
  font-size: 14px;
  text-align: center;
  font-family: "Geologica";
  font-weight: 200;
  line-height: 1;
  padding-top: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.home-container13 {
  flex: 0 0 auto;
  width: 400px;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image3 {
  width: 45px;
  object-fit: cover;
}
.home-text32 {
  font-size: 26px;
  font-style: normal;
  text-align: center;
  font-family: "Geologica";
  font-weight: 500;
  padding-top: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-text33 {
  color: rgb(173, 173, 173);
  font-size: 14px;
  text-align: center;
  font-family: "Geologica";
  font-weight: 200;
  line-height: 1;
  padding-top: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
}
.home-proccess {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-proccess-title {
  flex: 0 0 auto;
  width: 60vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-text34 {
  font-size: 3rem;
  text-align: center;
}
.home-text35 {
  color: #909090;
  font-size: 1.5rem;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
}
.home-container14 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-text36 {
  display: inline-block;
  font-size: 22px;
  text-align: left;
  line-height: 1;
}
.home-text37 {
  display: inline-block;
  text-align: left;
}
.home-text38 {
  display: inline-block;
  text-align: left;
}
.home-text39 {
  display: inline-block;
  text-align: left;
}
.home-text40 {
  display: inline-block;
  text-align: left;
}
.home-text41 {
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.home-text42 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.home-text43 {
  fill: #adadad;
  color: rgb(173, 173, 173);
  display: inline-block;
  text-align: left;
}
.home-text44 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.home-text45 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.home-cta {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text46 {
  display: inline-block;
}
.home-text47 {
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  max-width: 1600px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text48 {
  display: inline-block;
}
@media(max-width: 991px) {
  .home-container01 {
    flex-wrap: wrap;
  }
}
@media(max-width: 767px) {
  .home-container12 {
    margin-left: 0px;
  }
  .home-container13 {
    margin-left: 0px;
  }
}
