.card-container {
  width: auto;
  height: 500px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.card-card-1 {
  width: 290px;
  height: 420px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #202020;
  border-width: 1px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, rgb(19, 19, 19) 3.00%,rgb(13, 13, 13) 100.00%);
}
.card-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-image {
  width: auto;
  height: var(--dl-size-size-large);
}
.card-text {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 35px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
}
.card-text01 {
  line-height: 1.15;
}
.card-text02 {
  line-height: 1.15;
}
.card-card-2 {
  width: 290px;
  height: 420px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #202020;
  border-width: 1px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, rgb(19, 19, 19) 3.00%,rgb(13, 13, 13) 100.00%);
}
.card-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-image1 {
  width: auto;
  height: var(--dl-size-size-large);
}
.card-text03 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 35px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
}
.card-text04 {
  line-height: 1.15;
}
.card-text05 {
  line-height: 1.15;
}
.card-card-3 {
  width: 290px;
  height: 420px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #202020;
  border-width: 1px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, rgb(19, 19, 19) 3.00%,rgb(13, 13, 13) 100.00%);
}
.card-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-image2 {
  width: auto;
  height: var(--dl-size-size-large);
}
.card-text06 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 35px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
}
.card-text07 {
  line-height: 1.15;
}
.card-text08 {
  line-height: 1.15;
}
.card-card-4 {
  width: 290px;
  height: 420px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #202020;
  border-width: 1px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, rgb(19, 19, 19) 3.00%,rgb(13, 13, 13) 100.00%);
}
.card-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-image3 {
  width: auto;
  height: var(--dl-size-size-large);
}
.card-text09 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 35px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
}
.card-text10 {
  line-height: 1.15;
}
.card-text11 {
  line-height: 1.15;
}
.card-card-5 {
  width: 290px;
  height: 420px;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  border-color: #202020;
  border-width: 1px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, rgb(19, 19, 19) 3.00%,rgb(13, 13, 13) 100.00%);
}
.card-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.card-image4 {
  width: auto;
  height: var(--dl-size-size-large);
}
.card-text12 {
  fill: var(--dl-color-theme-primary2);
  color: var(--dl-color-theme-primary2);
  font-size: 35px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
}
.card-text13 {
  line-height: 1.15;
}
.card-text14 {
  line-height: 1.15;
}
.card-text15 {
  display: inline-block;
  text-align: left;
}
.card-text16 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.card-text17 {
  display: inline-block;
  text-align: left;
}
.card-text18 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.card-text19 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.card-text20 {
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.card-text21 {
  display: inline-block;
  text-align: left;
}
.card-text22 {
  fill: #adadad;
  color: #adadad;
  display: inline-block;
  font-size: 14px;
  text-align: left;
}
.card-text23 {
  display: inline-block;
  font-size: 22px;
  text-align: left;
  line-height: 1;
}
.card-text24 {
  display: inline-block;
  text-align: left;
}
.card-root-class-name {
  width: auto;
  height: auto;
}
@media(max-width: 767px) {
  .card-card-1 {
    width: 100%;
  }
  .card-card-2 {
    width: 100%;
  }
  .card-card-3 {
    width: 100%;
  }
  .card-card-4 {
    width: 100%;
  }
  .card-card-5 {
    width: 100%;
  }
}
