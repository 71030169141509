.nav-bar-container {
  width: var(--dl-size-size-maxwidth);
  height: auto;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.nav-bar-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  padding-left: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: center;
}
.nav-bar-container02 {
  top: 0;
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  position: fixed;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.nav-bar-navigation {
  flex: 0 0 auto;
  width: auto;
  height: 64px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-color: #333333;
  border-style: solid;
  border-width: 2px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  backdrop-filter: blur(10px);
  justify-content: center;
}
.nav-bar-container03 {
  flex-direction: row;
  justify-content: center;
}
.nav-bar-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
}
.nav-bar-container04:hover {
  border: none;
  background-color: #333333;
}
.nav-bar-text {
  font-weight: 500;
}
.nav-bar-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
}
.nav-bar-container05:hover {
  border: none;
  background-color: #333333;
}
.nav-bar-text01 {
  font-weight: 500;
}
.nav-bar-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
}
.nav-bar-container06:hover {
  border: none;
  background-color: #333333;
}
.nav-bar-text02 {
  font-weight: 500;
}
.nav-bar-contactcta {
  flex: 0 0 auto;
  width: 20%;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.nav-bar-link03 {
  display: contents;
}
.nav-bar-container07 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  margin-left: var(--dl-space-space-oneandhalfunits);
  padding-top: 0.5rem;
  border-color: #333333;
  border-width: 2px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: 0.5rem;
  backdrop-filter: blur(10px);
  justify-content: center;
  text-decoration: none;
  background-color: rgba(13, 13, 13, 0.79);
}
.nav-bar-container07:hover {
  border-color: var(--dl-color-theme-primary2);
  background-color: rgba(13, 13, 13, 0.79);
}
.nav-bar-text03 {
  font-weight: 500;
}
.nav-bar-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.nav-bar-desktop-menu {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-bar-logo {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.nav-bar-link04 {
  display: contents;
}
.nav-bar-image {
  width: auto;
  height: var(--dl-size-size-medium);
  box-shadow: 0px 0px 60px 0px rgba(236, 95, 255, 0.15);
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
  transition: 0.3s;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nav-bar-image:hover {
  box-shadow: 0px 0px 90px 0px rgba(236, 95, 255, 0.56);
}
.nav-bar-navigation1 {
  flex: 0 0 auto;
  width: auto;
  height: 64px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-color: #333333;
  border-style: solid;
  border-width: 2px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  backdrop-filter: blur(10px);
  justify-content: center;
}
.nav-bar-container08 {
  flex-direction: row;
  justify-content: center;
}
.nav-bar-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
}
.nav-bar-container09:hover {
  border: none;
  background-color: #333333;
}
.nav-bar-text04 {
  font-weight: 500;
}
.nav-bar-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
}
.nav-bar-container10:hover {
  border: none;
  background-color: #333333;
}
.nav-bar-text05 {
  font-weight: 500;
}
.nav-bar-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
}
.nav-bar-container11:hover {
  border: none;
  background-color: #333333;
}
.nav-bar-text06 {
  font-weight: 500;
}
.nav-bar-contactcta1 {
  flex: 0 0 auto;
  width: 20%;
  height: 64px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.nav-bar-link08 {
  display: contents;
}
.nav-bar-container12 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  margin-left: var(--dl-space-space-oneandhalfunits);
  padding-top: 0.5rem;
  border-color: #333333;
  border-width: 2px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: 0.5rem;
  backdrop-filter: blur(10px);
  justify-content: center;
  text-decoration: none;
  background-color: rgba(13, 13, 13, 0.79);
}
.nav-bar-container12:hover {
  border-color: var(--dl-color-theme-primary2);
  background-color: rgba(13, 13, 13, 0.79);
}
.nav-bar-text07 {
  font-weight: 500;
}
.nav-bar-burger-menu {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: space-between;
}
.nav-bar-logo1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.nav-bar-link09 {
  display: contents;
}
.nav-bar-image1 {
  width: auto;
  height: var(--dl-size-size-medium);
  box-shadow: 0px 0px 60px 0px rgba(236, 95, 255, 0.15);
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}
.nav-bar-image1:hover {
  box-shadow: 0px 0px 90px 0px rgba(236, 95, 255, 0.56);
}
.nav-bar-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.nav-bar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.nav-bar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nav-bar-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.nav-bar-link10 {
  display: contents;
}
.nav-bar-image2 {
  width: auto;
  height: var(--dl-size-size-medium);
  box-shadow: 0px 0px 60px 0px rgba(236, 95, 255, 0.15);
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}
.nav-bar-image2:hover {
  box-shadow: 0px 0px 90px 0px rgba(236, 95, 255, 0.56);
}
.nav-bar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.nav-bar-links {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.nav-bar-work {
  text-decoration: none;
}
.nav-bar-capabilities {
  text-decoration: none;
}
.nav-bar-process {
  text-decoration: none;
}
.nav-bar-buttons {
  gap: var(--dl-space-space-twounits);
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.nav-bar-link11 {
  display: contents;
}
.nav-bar-container13 {
  flex: 0 0 auto;
  width: 200px;
  height: var(--dl-size-size-small);
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  padding-top: 0.5rem;
  border-color: #333333;
  border-width: 2px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: 0.5rem;
  backdrop-filter: blur(10px);
  justify-content: center;
  text-decoration: none;
  background-color: rgba(13, 13, 13, 0.79);
}
.nav-bar-container13:hover {
  border-color: var(--dl-color-theme-primary2);
  background-color: rgba(13, 13, 13, 0.79);
}
.nav-bar-text08 {
  font-weight: 500;
}
.nav-bar-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.nav-bar-text09 {
  display: inline-block;
  text-align: center;
}
.nav-bar-text10 {
  color: var(--dl-color-theme-primary2);
}
.nav-bar-text12 {
  display: inline-block;
}

.nav-bar-text13 {
  display: inline-block;
}
.nav-bar-text14 {
  display: inline-block;
}
.nav-bar-text15 {
  display: inline-block;
  text-decoration: none;
}
.nav-bar-text16 {
  display: inline-block;
  text-decoration: none;
}
.nav-bar-text17 {
  display: inline-block;
  text-decoration: none;
}
.nav-bar-text18 {
  display: inline-block;
  text-align: center;
}
.nav-bar-text19 {
  color: var(--dl-color-theme-primary2);
}
.nav-bar-text21 {
  display: inline-block;
}

.nav-bar-text22 {
  display: inline-block;
}
.nav-bar-text23 {
  display: inline-block;
}
.nav-bar-text24 {
  display: inline-block;
  text-align: center;
}
.nav-bar-text25 {
  color: var(--dl-color-theme-primary2);
}
.nav-bar-root-class-name {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 150px;
  position: static;
  max-width: 1600px;
}
@media(max-width: 991px) {
  .nav-bar-navigation {
    height: 70px;
  }
  .nav-bar-desktop-menu {
    display: none;
  }
  .nav-bar-image {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .nav-bar-navigation1 {
    height: 70px;
  }
  .nav-bar-burger-menu {
    display: flex;
  }
  .nav-bar-image1 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .nav-bar-icon {
    width: var(--dl-size-size-small);
    height: auto;
  }
  .nav-bar-image2 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .nav-bar-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nav-bar-burger-menu {
    align-items: center;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .nav-bar-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .nav-bar-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
